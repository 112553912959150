// technologies.ts
export const TECHNOLOGY_ITEMS: Array<Technology> = [
  {
    title: "HTML5",
    icon: "assets/techLogos/html.svg",
    url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
  },

  {
    title: "CSS3",
    icon: "assets/techLogos/css.svg",
    url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
  },
  {
    title: "Python",
    icon: "assets/techLogos/python.svg",
    url: "https://www.python.org",
  },

  {
    title: "Django",
    icon: "assets/techLogos/django.svg",
    url: "https://www.djangoproject.com",
  },

  {
    title: "React",
    icon: "assets/techLogos/react.svg",
    url: "https://react.dev",
  },

  {
    title: "JavaScript",
    icon: "assets/techLogos/javascript.svg",
    url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },

  {
    title: "TypeScript",
    icon: "assets/techLogos/typescript.svg",
    url: "https://www.typescriptlang.org",
  },

  {
    title: "Next.js",
    icon: "assets/techLogos/next.svg",
    url: "https://nextjs.org",
  },

  {
    title: "Tailwind",
    icon: "assets/techLogos/tailwind.svg",
    url: "https://tailwindcss.com",
  },

  {
    title: "Django REST Framework",
    icon: "assets/techLogos/drf.svg",
    url: "https://www.django-rest-framework.org",
  },

  {
    title: "FastAPI",
    icon: "assets/techLogos/fast.svg",
    url: "https://fastapi.tiangolo.com",
  },

  {
    title: "Flask",
    icon: "assets/techLogos/flask.svg",
    url: "https://flask.palletsprojects.com",
  },

  {
    title: "GO Lang",
    icon: "assets/techLogos/golang.svg",
    url: "https://golang.org",
  },

  {
    title: "React Native",
    icon: "assets/techLogos/react_native.svg",
    url: "https://reactnative.dev",
  },

  {
    title: "Flutter",
    icon: "assets/techLogos/flutter.svg",
    url: "https://flutter.dev",
  },

  {
    title: "Android",
    icon: "assets/techLogos/android.svg",
    url: "https://developer.android.com",
  },

  {
    title: "Kotlin",
    icon: "assets/techLogos/kotlin.svg",
    url: "https://kotlinlang.org",
  },

  {
    title: "Swift",
    icon: "assets/techLogos/swift.svg",
    url: "https://developer.apple.com/swift",
  },

  {
    title: "Vue.js",
    icon: "assets/techLogos/vue.svg",
    url: "https://vuejs.org",
  },

  {
    title: "Angular",
    icon: "assets/techLogos/angular.svg",
    url: "https://angular.io",
  },

  {
    title: "Selenium",
    icon: "assets/techLogos/selenium.svg",
    url: "https://www.selenium.dev",
  },

  {
    title: "Figma",
    icon: "assets/techLogos/figma.svg",
    url: "https://www.figma.com",
  },

  {
    title: "Photoshop",
    icon: "assets/techLogos/photoshop.svg",
    url: "https://www.adobe.com/products/photoshop.html",
  },

  {
    title: "Illustrator",
    icon: "assets/techLogos/illustrator.svg",
    url: "https://www.adobe.com/products/illustrator.html",
  },

  {
    title: "Adobe XD",
    icon: "assets/techLogos/adobexd.svg",
    url: "https://www.adobe.com/products/xd.html",
  },

  {
    title: "Git",
    icon: "assets/techLogos/git.svg",
    url: "https://git-scm.com",
  },

  {
    title: "AWS",
    icon: "assets/techLogos/aws.svg",
    url: "https://aws.amazon.com",
  },
  {
    title: "Docker",
    icon: "assets/techLogos/docker.svg",
    url: "https://www.docker.com",
  },
  {
    title: "Kubernetes",
    icon: "assets/techLogos/kubernetes.svg",
    url: "https://kubernetes.io",
  },
  {
    title: "Jenkins",
    icon: "assets/techLogos/jenkins.svg",
    url: "https://www.jenkins.io",
  },
  {
    title: "NGINX",
    icon: "assets/techLogos/nginx.svg",
    url: "https://www.nginx.com",
  },
];
