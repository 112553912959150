import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "./CommonComps/SectionHeader";
import { ABOUT_CONTENT } from "../constants/about";
import formatText from "../utils/formatText";

const TimelineCircle = ({ title, active, progress, isFirst }: any) => (
  <div className="relative flex items-center gap-6">
    <div className="relative">
      <motion.div
        animate={{ backgroundColor: active ? "#f59e0b" : "#e1e1e1" }}
        className={`border-3 relative z-10 size-3 rounded-full border-amber-500 ${
          active ? "ring-2 ring-base-orange ring-offset-4" : ""
        }`}
      />
      {!isFirst && (
        <motion.div
          className="absolute -top-20 left-[4px] h-20 w-1 origin-top bg-gradient-to-t from-base-orange to-base-dark-orange"
          animate={{ scaleY: progress }}
        />
      )}
    </div>
    <span
      className={`font-montserrat text-lg font-medium tracking-wide ${
        active ? "text-amber-500" : "text-gray-500"
      }`}
    >
      {title}
    </span>
  </div>
);

const About = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [progress, setProgress] = useState<number[]>(
    new Array(ABOUT_CONTENT.length - 1).fill(0),
  );

  const handleScroll = useCallback(() => {
    const sections = document.querySelectorAll("[data-about-section]");
    const viewportHeight = window.innerHeight;

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();

      // Update active section
      if (
        rect.top <= viewportHeight * 0.6 &&
        rect.bottom >= viewportHeight * 0.4
      ) {
        setActiveSection(index);
      }

      // Update progress for lines between circles
      if (index < sections.length - 1) {
        const nextRect = sections[index + 1].getBoundingClientRect();
        const scrollProgress =
          (viewportHeight / 2 - rect.top) / (nextRect.top - rect.top);
        setProgress((prev) => {
          const newProgress = [...prev];
          newProgress[index] = Math.max(0, Math.min(1, scrollProgress));
          return newProgress;
        });
      }
    });
  }, []);

  useEffect(() => {
    setProgress(new Array(ABOUT_CONTENT.length - 1).fill(0));
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <SectionLayout
      sectionId="about"
      customClassName="relative !py-0 !pt-20 !pb-28"
      fullWidth
    >
      <SectionHeader headerText="about us" />
      <div className="custom-shape-divider-bottom-1731492067">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="mx-auto w-[95%] md:w-4/5">
        <div className="flex flex-col gap-8 md:flex-row">
          {/* Timeline */}
          <div className="hidden md:w-1/3 lg:flex lg:flex-col lg:items-center">
            <div className="sticky top-24 py-14">
              <h2 className="mb-12 font-montserrat text-2xl font-semibold">
                WHAT DEFINES US:
              </h2>
              <div className="space-y-16 pl-4">
                {ABOUT_CONTENT.map((item, index) => (
                  <TimelineCircle
                    key={item.timelineTitle}
                    title={item.timelineTitle}
                    active={index <= activeSection}
                    progress={index === 0 ? 1 : progress[index - 1]}
                    isFirst={index === 0}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="w-full space-y-10 pb-6 md:pb-32 lg:w-2/3 lg:space-y-24">
            {ABOUT_CONTENT.map((item, index) => (
              <div
                key={item.title}
                data-about-section
                className="rounded-lg px-8 md:translate-y-1/3 lg:py-8"
              >
                <motion.div
                  initial={{ opacity: 0, y: 300 }}
                  animate={{
                    opacity: index <= activeSection ? 1 : 0,
                    y: index <= activeSection ? 0 : 300,
                  }}
                  transition={{
                    delay: index * 0.05,
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                >
                  <h3 className="mb-4 font-montserrat text-2xl font-semibold leading-tight md:text-3xl lg:text-4xl">
                    {item.title}
                  </h3>
                  <p className="text-md tracking-wide text-base-dark-gray lg:text-lg">
                    {formatText(item.text)}
                  </p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default About;
