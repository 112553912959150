import React, { memo, type ReactNode } from "react";

import { Handle, Position, type Node, type NodeProps } from "@xyflow/react";

export type CustomNodeData = {
  title: string;
  icon?: ReactNode;
  subline?: string;
  number: number;
  handlePositions: {
    source: Position;
    target: Position;
  };
};

export default memo(({ data }: NodeProps<Node<CustomNodeData>>) => {
  return (
    <>
      <div className="cloud gradient">
        <div>
          <span className="text-sm font-semibold text-black">{data.icon}</span>
        </div>
      </div>
      <div className="wrapper gradient">
        <div className="inner">
          <div className="body">
            {data.icon && <div className="icon">{data.number}</div>}
            <div>
              <div className="title !mb-2">{data.title}</div>
              {data.subline && <div className="subline">{data.subline}</div>}
            </div>
          </div>
          <Handle type="target" position={data.handlePositions.target} />
          <Handle type="source" position={data.handlePositions.source} />
        </div>
      </div>
    </>
  );
});
