import React, { useEffect, useState } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  useReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import "@xyflow/react/dist/base.css";

import CustomNode from "./CustomNode";
import CustomEdge from "./CustomEdge";
import useWindowSize from "../../hooks/useWindowSize";
import { useWorkflowAnimation } from "../../hooks/useWorkflowAnimation";
import {
  getNodePositions,
  getHandlePositions,
} from "../../utils/workflowNodePositions";
import {
  WORKFLOW_STEPS,
  NODE_WIDTH,
  NODE_HEIGHT,
} from "../../constants/workflow";
import type { NodeId } from "../../types/workflow";

const createInitialNodes = (width: number) => {
  const positions = getNodePositions(width);
  const handlePositions = getHandlePositions(width);

  return WORKFLOW_STEPS.map(({ id, icon: Icon, title, subline }) => ({
    id,
    position: positions[id as NodeId],
    data: {
      number: parseInt(id),
      icon: <Icon className="h-5 w-5" />,
      title,
      subline,
      handlePositions: handlePositions[id as NodeId],
    },
    type: "custom",
    draggable: false,
    selectable: false,
    style: { width: NODE_WIDTH, height: NODE_HEIGHT },
  }));
};

const createInitialEdges = () =>
  WORKFLOW_STEPS.slice(0, -1).map((_, index) => ({
    id: `e${index + 1}-${index + 2}`,
    source: `${index + 1}`,
    target: `${index + 2}`,
    type: "custom",
  }));

const WorkflowDiagramInner = () => {
  const windowSize = useWindowSize();
  const { fitView } = useReactFlow();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const animationState = useWorkflowAnimation();

  const [nodes, setNodes, onNodesChange] = useNodesState(
    createInitialNodes(windowSize.width),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(createInitialEdges());

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        selected: node.id === animationState.nodeId,
      })),
    );

    setEdges((eds) =>
      eds.map((edge) => ({
        ...edge,
        className: edge.id === animationState.edgeId ? "animated-edge" : "",
        style: {
          strokeWidth: edge.id === animationState.edgeId ? 3 : 2,
        },
      })),
    );
  }, [animationState, setNodes, setEdges]);

  useEffect(() => {
    const positions = getNodePositions(windowSize.width);
    const handlePositions = getHandlePositions(windowSize.width);

    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        position: positions[node.id as NodeId],
        data: {
          ...node.data,
          handlePositions: handlePositions[node.id as NodeId],
        },
      })),
    );

    const timer = setTimeout(() => {
      fitView({
        padding: 0.2,
        duration: isInitialRender ? 0 : 200,
      });
      setIsInitialRender(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [windowSize.width, setNodes, fitView, isInitialRender]);

  return (
    <div className="pointer-events-none h-full w-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={{ custom: CustomNode }}
        edgeTypes={{ custom: CustomEdge }}
        defaultEdgeOptions={{
          type: "custom",
          markerEnd: "edge-arrow",
        }}
        fitView
        draggable={false}
        panOnDrag={false}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        minZoom={0.7}
        preventScrolling={false}
        nodesConnectable={false}
        selectNodesOnDrag={false}
        fitViewOptions={{
          padding: 0.2,
          duration: 0,
        }}
      >
        <svg>
          <defs>
            <linearGradient id="edge-gradient">
              <stop offset="0%" stopColor="#ae53ba" />
              <stop offset="100%" stopColor="#2a8af6" />
            </linearGradient>
            <marker
              id="edge-arrow"
              viewBox="-10 -10 20 20"
              refX="0"
              refY="0"
              markerUnits="strokeWidth"
              markerWidth="10"
              markerHeight="10"
              orient="auto"
            >
              <path
                d="M-5,-5 L5,0 L-5,5"
                fill="none"
                stroke="#f69e1a"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </marker>
          </defs>
        </svg>
      </ReactFlow>
    </div>
  );
};

const WorkflowDiagram = () => {
  return (
    <ReactFlowProvider>
      <WorkflowDiagramInner />
    </ReactFlowProvider>
  );
};

export default WorkflowDiagram;
