import { WORK_ITEMS } from "../constants/works";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "./CommonComps/SectionHeader";
import WorkCard from "./CommonComps/WorkCard";

const Works = () => {
  return (
    <SectionLayout
      sectionId="projects"
      customClassName="bg-[#f5f5f5] !py-0 !pt-20 !pb-28"
      fullWidth
    >
      <SectionHeader headerText="Our Projects" />
      <h2 className="mx-auto -mt-5 w-10/12 text-center font-montserrat text-lg md:text-2xl">
        <p>Check out what we have done for our clients.</p>
      </h2>

      <div className="mx-auto my-16 grid w-[95%] grid-cols-1 gap-10 md:w-4/5 2xl:grid-cols-2">
        {WORK_ITEMS.map((work, index) => (
          <WorkCard key={index} work={work} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default Works;
