import {
  LuPalette,
  LuCode2,
  LuClipboardList,
  LuSearch,
  LuRocket,
  LuWrench,
} from "react-icons/lu";
import { SiSpeedtest } from "react-icons/si";

export const BREAKPOINT_MD = 900;
export const NODE_WIDTH = 400;
export const NODE_HEIGHT = 125;
export const VERTICAL_SPACING = 200;

export const WORKFLOW_STEPS = [
  {
    id: "1",
    icon: LuClipboardList,
    title: "Planning",
    subline:
      "Laying the foundation with a clear roadmap tailored to your goals and vision.",
  },
  {
    id: "2",
    icon: LuSearch,
    title: "Requirement Analysis",
    subline:
      "Understanding your needs to define precise objectives and technical specifications.",
  },
  {
    id: "3",
    icon: LuPalette,
    title: "Design & Prototyping",
    subline:
      "Visualizing your ideas through detailed wireframes and interactive prototypes.",
  },
  {
    id: "4",
    icon: LuCode2,
    title: "Development",
    subline:
      "Building robust, scalable, and feature-rich applications with precision and care.",
  },
  {
    id: "5",
    icon: SiSpeedtest,
    title: "Testing",
    subline:
      "Ensuring reliability and performance with rigorous testing across all modules.",
  },
  {
    id: "6",
    icon: LuRocket,
    title: "Deployment",
    subline:
      "Seamlessly launching your solution to deliver maximum impact from day one.",
  },
  {
    id: "7",
    icon: LuWrench,
    title: "Maintenance",
    subline:
      "Providing ongoing support and updates to keep your software running smoothly.",
  },
] as const;
