import React from "react";
import { motion } from "framer-motion";

const NavButton = ({ label, href, customClass }: NavButton) => {
  // Spliting the label into an array of characters
  const chars = Array.from(label);

  return (
    <li>
      <motion.div
        initial={false}
        whileHover={"hover"}
        className="group relative block cursor-pointer overflow-hidden tracking-wide"
      >
        {/* Top text (black) */}
        <a href={href} className={` ${customClass} inline-block`}>
          {chars.map((char, index) => (
            <motion.span
              key={`top-${index}`}
              initial={{ y: 0 }}
              variants={{
                hover: { y: "-100%" },
              }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
                delay: index * 0.03,
              }}
              className="inline-block"
            >
              {char}
            </motion.span>
          ))}
        </a>

        {/* Bottom text (orange) - initially hidden, appears on hover */}
        <div className="absolute left-0 top-0">
          <a href={href} className="inline-block">
            {chars.map((char, index) => (
              <motion.span
                key={`bottom-${index}`}
                initial={{ y: "100%" }}
                variants={{
                  hover: { y: 0 },
                }}
                transition={{
                  duration: 0.6,
                  ease: "easeInOut",
                  delay: index * 0.03,
                }}
                className="inline-block text-base-orange"
              >
                {char}
              </motion.span>
            ))}
          </a>
        </div>
      </motion.div>
    </li>
  );
};
const MobileNavButton = ({
  label,
  href,
  onNavigate,
  customClass,
}: NavButton & { onNavigate: () => void }) => {
  return (
    <motion.li
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="w-full text-center"
    >
      <a
        href={href}
        onClick={(e) => {
          e.preventDefault();
          onNavigate();
          setTimeout(() => {
            window.location.href = href;
          }, 300);
        }}
        className={`${customClass} inline-block py-4 text-3xl text-base-black transition-colors hover:text-base-orange`}
      >
        {label}
      </a>
    </motion.li>
  );
};

export default NavButton;
export { MobileNavButton };
