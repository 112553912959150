import About from "./components/About";
import CEOProfile from "./components/CEOProfile";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import TechStack from "./components/TechStack";
import Testimonials from "./components/Testimonials";
import Works from "./components/Works";
import Workflow from "./components/Workflow";
import "./App.css";

function App() {
  return (
    <>
      <Header />

      <main className="font-poppins text-base-black">
        <Hero />
        <About />
        <Services />
        <Workflow />
        <Works />
        <Testimonials />
        <CEOProfile />
        <TechStack />
        <Contact />
      </main>

      <Footer />
    </>
  );
}

export default App;
