import React from "react";
import { FaQuoteLeft as Quote } from "react-icons/fa";
import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "./CommonComps/SectionHeader";
import { motion } from "framer-motion";
import { ceoProfileData } from "../constants/ceoProfile";

const CEOProfile = () => {
  return (
    <SectionLayout sectionId="CEO" customClassName="bg-[#f5f5f5]" fullWidth>
      <SectionHeader headerText="Words from our founder & CEO" />

      <div className="container mx-auto px-4 py-10 md:px-8 md:py-20">
        <div className="grid gap-8 md:gap-12 lg:grid-cols-12">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            viewport={{ once: true, amount: 0.5 }}
            className="lg:col-span-4"
          >
            <div className="relative mx-auto max-w-xs sm:max-w-sm">
              <div className="absolute -left-2 -top-2 h-full w-full -rotate-2 rounded-2xl bg-base-orange/10 md:-left-4 md:-top-4"></div>

              <div className="relative h-[400px] rotate-1 overflow-hidden rounded-2xl bg-white shadow-xl">
                <img
                  src={ceoProfileData.imageSrc}
                  alt={ceoProfileData.imageAlt}
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </motion.div>

          <div className="lg:col-span-8">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              viewport={{ once: true, amount: 0.5 }}
              className="relative space-y-6"
            >
              <Quote className="absolute -left-2 -top-10 size-12 text-base-orange/20 lg:-left-8 lg:-top-16 lg:size-16" />

              <div className="space-y-4 font-medium text-slate-600">
                {ceoProfileData.paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>

              <div className="mt-8 border-l-4 border-base-orange pl-6">
                <h4 className="text-2xl font-bold text-slate-900">
                  {ceoProfileData.name}
                </h4>
                <div className="text-sm text-slate-600">
                  <p className="font-medium">{ceoProfileData.role}</p>
                  <p>{ceoProfileData.company}</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default CEOProfile;
