const SectionHeader = ({
  headerText,
  customClassName,
}: {
  headerText: string;
  customClassName?: string;
}) => {
  // rendering section header component here
  return (
    <div className="mb-10 flex items-center justify-center">
      <div className="relative flex items-center justify-center px-10">
        <h1
          className={`text-center font-montserrat text-2xl font-semibold uppercase tracking-wide lg:text-3xl ${customClassName}`}
        >
          {headerText}
        </h1>

        <div className="absolute top-[110%] h-1 w-2/5 rounded-full bg-base-dark-orange"></div>
      </div>
    </div>
  );
};

export default SectionHeader;
