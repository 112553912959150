export const ABOUT_CONTENT: Array<About> = [
  {
    timelineTitle: "Our Excellence",
    title: "Passion for Quality and Innovation",
    text: "At Kirrhosoft, we are more than a team; we are a force of dedicated software professionals bound by a shared vision for innovation and uncompromising quality. Our mission is simple yet powerful: to set a new benchmark for software excellence in Bangladesh and taking it beyond our borders. We believe that great software isn't a coincidence—it's the result of meticulous *design*, relentless *testing*, and a profound *understanding* of our clients' needs.",
  },
  {
    timelineTitle: "Our Drive",
    title: "Driven by Self-Actualization",
    text: "What makes us unique? We don't just code; we craft with precision and we care profoundly about our deliverables. Hence, it's in our DNA to have a development process that fosters *design-first thinking*, ensuring rigorous attention to details, through *extensive testing* at every stage and *continuous deployment* to ensure flawless functionality. From the ground up, we design solutions that are extremely *usable, scalable, secure,* and ready to meet the challenges of tomorrow. For us, these principles are not an afterthought; it's a fundamental part of every design and coding decision we make.",
  },
  {
    timelineTitle: "Our Vision",
    title: "A Vision Rooted in Experience",
    text: "Kirrhosoft was founded by a seasoned software engineer with decades of international experience, who returned to Bangladesh with a dream to establish a company that adheres to highest of standards, local or beyond. While Our journey began with a small team and humble goals, we were always focused on delivering quality software that transformed business processes of our clients. As we grow *our focus remains the same*: to continue *bringing impactful*, *high-quality software solutions to life*, whatever may the business needs be. We are here to empower your business with the power of our software and expertise.",
  },
];
