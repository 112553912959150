import { NodePosition, HandlePosition, NodeId } from "../types/workflow";
import {
  BREAKPOINT_MD,
  NODE_WIDTH,
  VERTICAL_SPACING,
  WORKFLOW_STEPS,
} from "../constants/workflow";
import { Position } from "@xyflow/react";

export const getNodePositions = (
  width: number,
): Record<NodeId, NodePosition> => {
  const isMobile = width < BREAKPOINT_MD;
  const centerX = width / 2 - NODE_WIDTH / 2;

  if (isMobile) {
    return WORKFLOW_STEPS.reduce(
      (acc, { id }, index) => ({
        ...acc,
        [id]: { x: centerX, y: VERTICAL_SPACING * index },
      }),
      {} as Record<NodeId, NodePosition>,
    );
  }

  const leftColumn = centerX - (NODE_WIDTH + 0);
  const rightColumn = centerX + (NODE_WIDTH + 0);

  return {
    "1": { x: centerX, y: 0 },
    "2": { x: leftColumn, y: VERTICAL_SPACING },
    "3": { x: rightColumn, y: VERTICAL_SPACING },
    "4": { x: centerX, y: VERTICAL_SPACING * 2 },
    "5": { x: leftColumn, y: VERTICAL_SPACING * 3 },
    "6": { x: rightColumn, y: VERTICAL_SPACING * 3 },
    "7": { x: centerX, y: VERTICAL_SPACING * 4 },
  };
};

export const getHandlePositions = (
  width: number,
): Record<NodeId, HandlePosition> => {
  const isMobile = width < BREAKPOINT_MD;
  const defaultPositions = {
    source: Position.Bottom,
    target: Position.Top,
  };

  if (isMobile) {
    return WORKFLOW_STEPS.reduce(
      (acc, { id }) => ({
        ...acc,
        [id]: defaultPositions,
      }),
      {} as Record<NodeId, HandlePosition>,
    );
  }

  return {
    "1": { source: Position.Bottom, target: Position.Left },
    "2": { source: Position.Right, target: Position.Top },
    "3": { source: Position.Bottom, target: Position.Left },
    "4": { source: Position.Bottom, target: Position.Top },
    "5": { source: Position.Right, target: Position.Top },
    "6": { source: Position.Bottom, target: Position.Left },
    "7": { source: Position.Right, target: Position.Top },
  };
};
