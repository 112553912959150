import { useState, useEffect } from "react";
import { AnimationState } from "../types/workflow";

export const useWorkflowAnimation = (interval = 3000) => {
  const [animationState, setAnimationState] = useState<AnimationState>({
    nodeId: "1",
    edgeId: null,
  });

  const getNextAnimationState = (current: AnimationState): AnimationState => {
    const currentNodeNumber = parseInt(current.nodeId);
    if (currentNodeNumber >= 7) {
      return { nodeId: "1", edgeId: null };
    }
    const nextNodeNumber = currentNodeNumber + 1;
    return {
      nodeId: nextNodeNumber.toString(),
      edgeId: `e${currentNodeNumber}-${nextNodeNumber}`,
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimationState((current) => getNextAnimationState(current));
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  return animationState;
};
