import React from "react";
import {
  COMPANY_INFO,
  CONTACT_INFO,
  SOCIAL_LINKS,
  MAP_URL,
} from "../constants/footer";

const CompanySection = () => (
  <div className="flex flex-col items-center lg:flex-row lg:items-start">
    <div>
      <img className="size-40" src={COMPANY_INFO.logo} alt="Kirrhosoft logo" />
    </div>
    <div className="flex w-full flex-col md:w-96 lg:w-72">
      <h1 className="mx-auto text-3xl font-bold lg:mx-0">
        <span className="text-base-orange">{COMPANY_INFO.name.first}</span>
        <span className="text-base-green">{COMPANY_INFO.name.second}</span>
      </h1>
      <div>
        <p className="text-center text-base-white lg:text-start">
          {COMPANY_INFO.description}
        </p>
      </div>
    </div>
  </div>
);

const MapSection = () => (
  <div className="flex-1">
    <p className="pb-2 text-center font-bold tracking-wider text-base-white md:text-start">
      LOCATE US ON GOOGLE MAP:
    </p>
    <iframe
      title="Kirrhosoft Location"
      src={MAP_URL}
      style={{ border: 0 }}
      width="100%"
      height="200"
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      className="rounded-sm shadow-lg"
    />
  </div>
);

const ContactSection = () => (
  <div className="flex flex-col items-center space-y-10 md:items-start lg:flex-row lg:justify-center lg:space-x-8 lg:space-y-0">
    <div className="flex flex-col items-start gap-3">
      <p className="text-center font-bold tracking-wider text-base-white">
        CONTACTS:
      </p>

      {CONTACT_INFO.map((contact, index) => (
        <div key={index} className="flex items-start gap-2">
          <contact.icon className="size-6 text-base-orange" />
          <p className="text-base-white">{contact.text}</p>
        </div>
      ))}

      <div className="space-y-1 pt-2 text-start">
        <p className="font-bold tracking-wider text-base-white">FIND US ON:</p>
        <div className="flex items-center justify-center gap-3 lg:justify-start">
          {SOCIAL_LINKS.map((social, index) => (
            <a
              key={index}
              href={social.url}
              className="duration-300 hover:opacity-70"
              target="_blank"
              rel="noreferrer"
              aria-label={social.label}
            >
              <social.icon className="size-8 text-base-orange" />
            </a>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const Footer: React.FC = () => {
  return (
    <footer className="bg-base-black pt-40">
      <div className="mx-auto flex w-[90%] flex-col items-center justify-center gap-16 py-10 md:flex-row xl:w-4/5 xl:justify-between">
        <CompanySection />
        <div className="flex flex-col gap-10 xl:flex-1 xl:flex-row">
          <MapSection />
          <ContactSection />
        </div>
      </div>

      <div className="flex items-center justify-center bg-gray-100 py-1 text-sm">
        <span>
          © Copyright{" "}
          <span className="font-bold text-base-orange">
            {COMPANY_INFO.name.first + COMPANY_INFO.name.second}
          </span>
          . All Rights Reserved. {new Date().getFullYear()}.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
