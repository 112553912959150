import {
  FaXTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa6";
import { LuClock } from "react-icons/lu";
import { HiOutlinePhone } from "react-icons/hi";
import { IoMailOutline, IoLocationOutline } from "react-icons/io5";

export interface ContactInfo {
  icon: typeof IoLocationOutline;
  text: string | React.ReactNode;
}

export interface SocialLink {
  icon: typeof FaLinkedinIn;
  url: string;
  label: string;
}

export const COMPANY_INFO = {
  name: {
    first: "Kirrho",
    second: "soft",
  },
  description:
    "At Kirrhosoft, we build innovative, high-quality software solutions rooted in precision, security, and a commitment to excellence.",
  logo: "assets/icons/k_soft_logo.svg",
};

export const CONTACT_INFO: ContactInfo[] = [
  {
    icon: IoLocationOutline,
    text: "House-01, Road-04, Banani (DOHS), Dhaka-1206",
  },
  {
    icon: LuClock,
    text: "Sun - Thu: 11:00 AM - 5:00 PM",
  },
  {
    icon: IoMailOutline,
    text: "info@kirrhosoft.com",
  },
  {
    icon: HiOutlinePhone,
    text: "+8801301-036959",
  },
];

export const SOCIAL_LINKS: SocialLink[] = [
  {
    icon: FaLinkedinIn,
    url: "https://www.linkedin.com/company/Kirrhosoft-ltd/",
    label: "LinkedIn",
  },
  {
    icon: FaFacebookF,
    url: "https://www.facebook.com/kirrhosoft/",
    label: "Facebook",
  },
  {
    icon: FaInstagram,
    url: "https://www.instagram.com/Kirrhosoft/",
    label: "Instagram",
  },
  {
    icon: FaXTwitter,
    url: "https://x.com/Kirrhosoft_ltd",
    label: "Twitter",
  },
];

export const MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d294.4496294896075!2d90.39983705124807!3d23.79413873929977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c79d1f491479%3A0x78b2f49da08c049e!2sKirrhosoft!5e0!3m2!1sen!2sbd!4v1730958502571!5m2!1sen!2sbd";
