import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "./CommonComps/SectionHeader";
import WorkflowDiagram from "./CommonComps/WorkflowDiagram";

const Workflow = () => {
  return (
    <SectionLayout
      sectionId="workflow"
      customClassName="!py-0 !pt-20"
      fullWidth
    >
      <SectionHeader headerText="How We Work" />
      <h2 className="mx-auto -mt-5 w-10/12 text-center font-montserrat text-lg md:text-lg">
        <p>
          We follow a clear and efficient workflow to deliver exceptional
          software solutions.
        </p>
      </h2>

      <div className="h-[125vh] w-full xl:h-[120vh]">
        <WorkflowDiagram />
      </div>
    </SectionLayout>
  );
};

export default Workflow;
