interface CEOProfileData {
  name: string;
  role: string;
  company: string;
  imageSrc: string;
  imageAlt: string;
  paragraphs: string[];
}

export const ceoProfileData: CEOProfileData = {
  name: "Farhan Sajjad",
  role: "Founder & CEO",
  company: "Kirrhosoft",
  imageSrc: "assets/images/sajjadfx.jpg",
  imageAlt: "Farhan Sajjad - CEO",
  paragraphs: [
    "My vision is simple: to run a company that adheres to international standards of quality and efficiency. Throughout my career, I noticed a recurring challenge: the gap between ambitious project goals and the realities of delivery. However, this gap can be closed if the right processes and checks are in place. I think with my experience, it is my responsibility to be involved in establishing a culture that ensures a mindset of excellence, no matter who touches the code. That is Kirrhosoft.",

    "I also envision a future, beyond us and our clients, where Bangladeshi software industry is recognized globally for its skills and creativity. Albeit, inspired by Kirrhosoft and similar startups, where we will build a reputation for delivering highly reputable software that meets the most demanding of deadlines.",

    "Thank you for being part of this journey. Together, we can drive change, push boundaries, and make a lasting impact on the technology landscape in Bangladesh and beyond.",
  ],
};
