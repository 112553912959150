import { motion } from "framer-motion";
import { useState } from "react";
import Marquee from "react-fast-marquee";
import { IoIosReturnRight } from "react-icons/io";
import { TbPointFilled } from "react-icons/tb";
import { IoChevronDown as ChevronDown } from "react-icons/io5";

interface ourServices {
  title: string;
  description: string;
  icon: string[];
}

const DesktopIconScroll = ({
  icons,
  title,
}: {
  icons: string[];
  title: string;
}) => {
  if (icons.length <= 5) {
    return (
      <div className="flex justify-end">
        {icons.map((iconSrc, iconIndex) => (
          <img
            key={iconIndex}
            src={iconSrc}
            alt={`${title} icon ${iconIndex + 1}`}
            className="mx-6 flex-shrink-0 lg:size-12 xl:size-14 2xl:size-16"
          />
        ))}
      </div>
    );
  }

  return (
    <div className="relative">
      {/* left faded gradient */}
      <div className="pointer-events-none absolute left-0 top-0 z-10 m-0 h-full w-[20%] border-none bg-gradient-to-r from-gray-100 to-transparent p-0" />
      {/* right faded gradient */}
      <div className="pointer-events-none absolute right-0 top-0 z-10 h-full w-[20%] border-none bg-gradient-to-l from-gray-100 to-transparent" />
      <div className="absolute inset-0" style={{ contain: "strict" }}>
        <Marquee speed={40} gradient={false} className="h-full">
          {icons.map((iconSrc, iconIndex) => (
            <img
              key={iconIndex}
              src={iconSrc}
              alt={`${title} icon ${iconIndex + 1}`}
              className="mx-6 flex-shrink-0 lg:size-12 xl:size-14 2xl:size-16"
            />
          ))}
        </Marquee>
      </div>
      <div className="opacity-0">
        {/* Placeholder to maintain height */}
        <img
          src={icons[0]}
          alt="placeholder"
          className="invisible mx-6 flex-shrink-0 lg:size-12 xl:size-14 2xl:size-16"
        />
      </div>
    </div>
  );
};

const DesktopCard = ({ service }: { service: ourServices }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="hidden w-full rounded-lg bg-gray-100 p-10 shadow-md lg:block"
    >
      <div className="flex items-start justify-between gap-10">
        <div className="flex w-1/2 flex-col">
          <h3 className="z-20 flex items-center bg-gray-100 font-montserrat font-semibold lg:text-2xl xl:text-3xl 2xl:text-4xl">
            <TbPointFilled className="mr-2 size-8 text-base-orange" />
            <span>{service.title}</span>
          </h3>

          <motion.div
            initial={{ height: 0 }}
            animate={{ height: isHovered ? "auto" : 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : -50 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="mt-2"
            >
              <div className="ml-4 flex space-x-1">
                <span>
                  <IoIosReturnRight className="size-6" />
                </span>
                <p className="tracking-wide">{service.description}</p>
              </div>
            </motion.div>
          </motion.div>
        </div>

        <div className="w-1/2 overflow-hidden">
          <DesktopIconScroll icons={service.icon} title={service.title} />
        </div>
      </div>
    </div>
  );
};

const MobileAccordion = ({ service }: { service: ourServices }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="w-[90%] lg:hidden">
      <div className="overflow-hidden rounded-lg bg-gray-100">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex h-16 w-full items-center justify-between px-4 py-4"
        >
          <div className="flex items-center">
            <TbPointFilled className="mr-2 size-6 text-base-orange" />
            <span className="font-montserrat text-lg font-semibold">
              {service.title}
            </span>
          </div>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className="size-5" />
          </motion.div>
        </button>

        <motion.div
          initial={false}
          animate={{ height: isExpanded ? "auto" : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="overflow-hidden"
        >
          <div className="space-y-8 px-4 pb-4">
            <div className="flex space-x-1">
              <IoIosReturnRight className="size-6 flex-shrink-0" />
              <p className="tracking-wide">{service.description}</p>
            </div>

            <div className="relative overflow-hidden">
              {/* left gradient */}
              <div className="pointer-events-none absolute -left-5 top-0 z-10 m-0 h-full w-[25%] border-none bg-gradient-to-r from-gray-100 to-transparent p-0" />
              {/* right gradient */}
              <div className="pointer-events-none absolute -right-5 top-0 z-10 h-full w-[25%] border-none bg-gradient-to-l from-gray-100 to-transparent" />
              {service.icon.length > 5 ? (
                <Marquee speed={40} gradient={false}>
                  {service.icon.map((iconSrc, iconIndex) => (
                    <img
                      key={iconIndex}
                      src={iconSrc}
                      alt={`${service.title} icon ${iconIndex + 1}`}
                      className="mx-4 size-10"
                    />
                  ))}
                </Marquee>
              ) : (
                <div className="flex flex-wrap justify-center gap-4">
                  {service.icon.map((iconSrc, iconIndex) => (
                    <img
                      key={iconIndex}
                      src={iconSrc}
                      alt={`${service.title} icon ${iconIndex + 1}`}
                      className="size-10"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const ServiceCard = ({ service }: { service: ourServices }) => {
  return (
    <>
      <DesktopCard service={service} />
      <MobileAccordion service={service} />
    </>
  );
};

export default ServiceCard;
