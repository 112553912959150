import { FaUser } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";

interface TestimonialCardProps {
  testimonial: Testimonial;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const TestimonialCard = ({
  testimonial,
  onMouseEnter,
  onMouseLeave,
}: TestimonialCardProps) => {
  const { image, name, designation, comments } = testimonial || {};

  return (
    <div
      className="mx-10 flex min-w-[450px] max-w-[450px] flex-col rounded-lg bg-base-white p-4 shadow-kirrhosoft-sm transition-transform hover:scale-105 hover:cursor-grab"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex w-full items-center space-x-3 border-b-2 pb-3">
        <div>
          {image ? (
            <figure className="size-14 overflow-hidden rounded-full border-2 border-base-orange">
              <img
                className="object-contain"
                src={image}
                alt={`avatar of ${name}`}
              />
            </figure>
          ) : (
            <figure className="flex size-14 items-center justify-center overflow-hidden rounded-full border-2 border-base-orange bg-base-white p-4">
              <FaUser className="size-12 text-gray-300" />
            </figure>
          )}
        </div>
        <div className="flex flex-col justify-start">
          <figcaption>
            <div>
              <h3 className="text-lg font-medium leading-4">{name}</h3>
              <span className="text-sm text-base-dark-gray">{designation}</span>
            </div>
          </figcaption>
        </div>
      </div>
      <div className="flex items-start p-3">
        <span>
          <RiDoubleQuotesL className="text-gray-500" />
        </span>
        <span>
          <p className="font-montserrat italic">{comments}</p>
        </span>
      </div>
    </div>
  );
};

export default TestimonialCard;
