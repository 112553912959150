export const NAVBAR_ITEMS: Array<NavButton> = [
  //   {
  //     href: '#hero',
  //     label: 'Home',
  //   },

  {
    href: "#about",
    label: "ABOUT",
  },

  {
    href: "#services",
    label: "SERVICES",
  },

  {
    href: "#workflow",
    label: "WORKFLOW",
  },

  {
    href: "#projects",
    label: "PROJECTS",
  },

  {
    href: "#testimonials",
    label: "TESTIMONIALS",
  },

  // {
  //   href: "#contact",
  //   label: "CONTACT",
  // },
];
