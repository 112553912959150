import SectionLayout from "../layouts/SectionLayout";
import SectionHeader from "./CommonComps/SectionHeader";
import { OUR_SERVICE_ITEMS } from "../constants/ourServices";
import ServiceCard from "./CommonComps/ServiceCard";

const Services = () => {
  return (
    <SectionLayout
      sectionId="services"
      customClassName="bg-base-white relative !py-0 !pt-20 !pb-28"
      fullWidth
    >
      <SectionHeader headerText="Our Services" />

      <div className="custom-shape-divider-bottom-1731492448">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <h2 className="mx-auto -mt-5 w-10/12 text-center font-montserrat text-base md:text-lg">
        <p>
          As a software development company, we offer extensive technical and
          domain proficiency to foster the growth of your business.
        </p>
      </h2>

      <div className="mx-auto mt-10 flex flex-col items-center justify-center space-y-5 md:mt-16 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
        {OUR_SERVICE_ITEMS.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default Services;
