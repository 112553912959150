import { motion } from "framer-motion";
// import { FiExternalLink } from "react-icons/fi";

const WorkCard = ({ work }: { work: Work }) => {
  const { image, title, subtitle, description } = work || {};

  return (
    <motion.div
      className="cursor-pointer overflow-hidden rounded-xl bg-white shadow-lg"
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      viewport={{ once: true, amount: 0.33 }}
    >
      {/* Card Image */}
      <div className="relative border-b-2">
        <figure>
          <img
            className="h-full w-full object-fill duration-300 hover:scale-105"
            src={image}
            alt={`figure of ${title} project`}
          />
        </figure>
      </div>

      {/* Card Details */}
      <motion.div className="space-y-3 p-6">
        <div className="flex flex-col items-start justify-between gap-3 border-b border-gray-200 pb-2 md:flex-row md:items-center lg:flex-col lg:items-start xl:flex-row xl:items-center">
          <div>
            <p className="text-sm text-gray-400">{subtitle}</p>
            <h2 className="font-montserrat text-xl font-semibold">{title}</h2>
          </div>
          <div>
            {/* COMMENTED BECAUSE THERE ARE NO LIVE DEMO LINK YET */}
            {/* <button className="group relative flex items-center space-x-2 rounded-none border-2 border-base-green bg-base-light-green px-4 py-2 text-lg font-medium text-base-green transition duration-300 ease-in-out hover:border-base-green hover:text-base-white active:scale-95 md:shadow-lg">
              <span className="absolute bottom-0 left-0 h-0 w-0 rounded-none bg-base-green duration-300 ease-in-out group-hover:h-full group-hover:w-full"></span>
              <span className="z-10 font-montserrat text-sm font-medium">
                View Demo
              </span>
              <span className="z-10 text-base-green duration-300 ease-out group-hover:text-base-white">
                <FiExternalLink className="size-4" />
              </span>
            </button> */}
          </div>
        </div>
        <div className="flex h-2/4 flex-col items-start justify-between">
          <p className="text-base-dark-gray">{description}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default WorkCard;
