import { useEffect, useState, useMemo, memo, useRef } from "react";
import { TbHexagonFilled } from "react-icons/tb";

interface DotProps {
  isActive: boolean;
  spacing: number;
}

interface DotRowProps {
  rowIndex: number;
  dotsPerRow: number;
  spacing: number;
  activeDots: Set<number>;
}

interface Dimensions {
  rows: number;
  dotsPerRow: number;
}

// single dot
const Dot = memo(({ isActive, spacing }: DotProps) => (
  <div
    className="flex items-center justify-center"
    style={{
      width: spacing,
      transform: isActive
        ? "scale(1.75) rotate(360deg)"
        : "scale(.5) rotate(0deg)",
      transition: "transform 0.5s ease-in-out",
    }}
  >
    <TbHexagonFilled
      size={16}
      className={`transition duration-500 ${
        isActive ? "text-[#FFEFD5]" : "text-[#f6f6f6]"
      }`}
    />
  </div>
));

Dot.displayName = "Dot";

// row of dots
const DotRow = memo(
  ({ rowIndex, dotsPerRow, spacing, activeDots }: DotRowProps) => (
    <div className="flex" style={{ height: spacing }}>
      {Array.from({ length: dotsPerRow }, (_, colIndex) => {
        const dotIndex = rowIndex * dotsPerRow + colIndex;
        return (
          <Dot
            key={colIndex}
            isActive={activeDots.has(dotIndex)}
            spacing={spacing}
          />
        );
      })}
    </div>
  ),
);

DotRow.displayName = "DotRow";

const AnimatedDotPattern = () => {
  const spacing = 60;
  const INITIAL_RENDER_DELAY = 2000; // 2 seconds delay for initial render
  const ANIMATION_DELAY = 1000; // 1 seconds delay for animation start
  const [dimensions, setDimensions] = useState<Dimensions>({
    rows: 0,
    dotsPerRow: 0,
  });
  const [activeDots, setActiveDots] = useState<Set<number>>(new Set());
  const [isInitialRender, setIsInitialRender] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // Calculates grid dimensions
  useEffect(() => {
    const node = containerRef.current;
    if (!node) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      const { width, height } = entry.contentRect;

      setDimensions({
        rows: Math.floor(height / spacing),
        dotsPerRow: Math.floor(width / spacing),
      });
    });

    observer.observe(node);
    return () => observer.disconnect();
  }, [spacing]);

  // Initial render delay
  useEffect(() => {
    const initialRenderTimeout = setTimeout(() => {
      setIsInitialRender(true);

      // Start animation delay after the initial render
      const animationTimeout = setTimeout(() => {
        setIsAnimationStarted(true);
      }, ANIMATION_DELAY);

      return () => clearTimeout(animationTimeout);
    }, INITIAL_RENDER_DELAY);

    return () => clearTimeout(initialRenderTimeout);
  }, []);

  // Animation effect
  useEffect(() => {
    if (
      !isAnimationStarted ||
      dimensions.rows === 0 ||
      dimensions.dotsPerRow === 0
    )
      return;

    const totalDots = dimensions.rows * dimensions.dotsPerRow;
    const getRandomDotIndex = () => Math.floor(Math.random() * totalDots);
    let animationFrameId: number;
    let lastUpdate = 0;

    const animate = (timestamp: number) => {
      if (timestamp - lastUpdate >= 1000) {
        setActiveDots((prev) => {
          const newSet = new Set(prev);
          if (newSet.size >= 5) {
            const firstItem = newSet.values().next().value;
            if (firstItem !== undefined) {
              newSet.delete(firstItem);
            }
          }
          newSet.add(getRandomDotIndex());
          return newSet;
        });
        lastUpdate = timestamp;
      }
      animationFrameId = window.requestAnimationFrame(animate);
    };

    animationFrameId = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(animationFrameId);
  }, [dimensions, isAnimationStarted]);

  const grid = useMemo(
    () =>
      Array.from({ length: dimensions.rows }, (_, rowIndex) => (
        <DotRow
          key={rowIndex}
          rowIndex={rowIndex}
          dotsPerRow={dimensions.dotsPerRow}
          spacing={spacing}
          activeDots={activeDots}
        />
      )),
    [dimensions.rows, dimensions.dotsPerRow, activeDots, spacing],
  );

  return (
    <div
      ref={containerRef}
      className="pointer-events-none absolute inset-0 h-full w-full overflow-hidden"
    >
      {isInitialRender && (
        <div className="flex h-full w-full flex-col items-center justify-center">
          {grid}
        </div>
      )}
    </div>
  );
};

export default AnimatedDotPattern;
