import React from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { MdOutlineErrorOutline } from "react-icons/md";
import emailjs from "@emailjs/browser";
import SectionLayout from "../layouts/SectionLayout";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormValues {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const initialValues: FormValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const CustomErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <div className="mt-1 flex items-center gap-1 rounded px-2 py-1">
        <MdOutlineErrorOutline className="size-5 text-red-400" />
        <span className="text-sm font-semibold text-red-400">{msg}</span>
      </div>
    )}
  </ErrorMessage>
);

const Contact = () => {
  // Initialize EmailJS when component mounts
  React.useEffect(() => {
    emailjs.init("fTpOrIl1AKBbiPVml");
  }, []);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>,
  ) => {
    try {
      // EmailJS credentials
      const SERVICE_ID = "service_h5tginj";
      const TEMPLATE_ID = "template_ltj3mzi";

      const templateParams = {
        from_name: values.name,
        from_email: values.email,
        subject: values.subject,
        message: values.message,
      };

      const result = await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        templateParams,
      );

      if (result.status === 200) {
        toast.success(
          "Message sent successfully! We'll get back to you soon.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          },
        );
        resetForm();
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SectionLayout
      sectionId="contact"
      customClassName="bg-[#fff] !py-0"
      fullWidth
    >
      <ToastContainer style={{ zIndex: 9999, marginTop: "4rem" }} />
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.5 }}
        className="relative top-32 mx-auto flex w-[95%] flex-col space-y-4 rounded-2xl bg-base-light-orange px-8 py-6 md:w-[75%] md:px-10 md:py-8 lg:w-[65%] xl:w-[55%]"
      >
        <div className="flex flex-col justify-center space-y-3">
          <h3 className="font-montserrat text-4xl font-bold text-base-black/90 md:text-5xl">
            Contact Us
          </h3>
          <p className="text-base leading-relaxed tracking-tight text-base-black/70 md:text-lg">
            Let's resolve your inquiry. Simply fill out the form with your
            details, and we'll connect with you and find the right solution.
          </p>
        </div>

        <div className="rounded-lg bg-transparent">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                {[
                  {
                    name: "name",
                    label: "Name",
                    type: "text",
                    placeholder: "Enter your full name",
                  },
                  {
                    name: "email",
                    label: "Email",
                    type: "email",
                    placeholder: "Enter your email",
                  },
                  {
                    name: "subject",
                    label: "Subject",
                    type: "text",
                    placeholder: "Enter the topic of your inquiry",
                  },
                  {
                    name: "message",
                    label: "Message",
                    type: "text",
                    placeholder: "Enter your message",
                    as: "textarea",
                  },
                ].map((field) => (
                  <div key={field.name}>
                    <label
                      htmlFor={field.name}
                      className="p-1 text-base font-bold text-base-black/70 md:text-lg"
                    >
                      {field.label}
                    </label>
                    <Field
                      autoComplete="off"
                      id={field.name}
                      name={field.name}
                      type={field.type}
                      as={field.as}
                      placeholder={field.placeholder}
                      className="mt-1 w-full border-b border-gray-300 bg-transparent px-2 py-2 caret-black placeholder:text-sm placeholder:text-base-black/30 focus:border-b-2 focus:border-base-black/70 focus:outline-none"
                    />
                    <CustomErrorMessage name={field.name} />
                  </div>
                ))}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full rounded-xl border bg-base-orange py-4 font-semibold text-base-black transition duration-200 hover:scale-95 active:scale-90 disabled:opacity-50"
                >
                  <span className="text-lg tracking-wide text-base-white">
                    {isSubmitting ? "Sending..." : "Send a Message"}
                  </span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </motion.div>
    </SectionLayout>
  );
};

export default Contact;
