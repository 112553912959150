import SectionLayout from "../layouts/SectionLayout";
// import HighlightedText from "./CommonComps/HighlightedText";
import { PiArrowBendRightDownBold } from "react-icons/pi";
import AnimatedDotPattern from "./CommonComps/AnimatedDotPattern";
import { motion } from "framer-motion";

const Hero = () => {
  // rendering hero section component here
  return (
    <SectionLayout
      sectionId="hero"
      customClassName="bg-base-white relative overflow-hidden h-[calc(100vh-64px)]"
      fullWidth
    >
      <div className="flex h-full flex-col items-center justify-center">
        <AnimatedDotPattern />
        {/* title, description, button */}
        <div className="relative z-10 flex flex-col items-center justify-center space-y-16 lg:w-10/12">
          {/* title and svg */}
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
            }}
            className="relative w-full space-y-4 text-center font-montserrat text-3xl font-semibold sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl"
          >
            {/* Title */}
            <div className="space-y-2">
              <h1 className="flex w-full flex-col items-center justify-center space-y-2 text-base-dark-gray md:flex-row md:space-x-5">
                <span>Grow </span>
                <motion.span
                  className="relative hidden overflow-hidden md:inline-flex"
                  initial={{ width: 0 }}
                  animate={{ width: "auto" }}
                  transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
                >
                  <motion.span
                    className="absolute inset-0 rounded-lg bg-base-green"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    transition={{
                      delay: 1,
                      duration: 1,
                      ease: "easeInOut",
                    }}
                    style={{ originX: 0 }}
                  />
                  <span className="relative z-10 whitespace-nowrap px-1 py-2 text-base-white">
                    Beyond Expectations
                  </span>
                </motion.span>
                {/* For Mobile Screen */}
                <motion.span
                  className="relative inline-flex overflow-hidden md:hidden"
                  initial={{ height: 0, width: 0 }}
                  animate={{
                    height: "auto",
                    width: "auto",
                    transition: {
                      width: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
                      height: { delay: 1, duration: 1, ease: "easeInOut" },
                    },
                  }}
                >
                  <motion.span
                    className="absolute inset-0 rounded-lg bg-base-green"
                    initial={{ scaleY: 0 }}
                    animate={{ scaleY: 1 }}
                    transition={{
                      delay: 1,
                      duration: 1,
                      ease: "easeInOut",
                    }}
                    style={{ originY: 0 }}
                  />
                  <span className="relative z-10 whitespace-nowrap px-1 py-2 text-base-white">
                    Beyond Expectations
                  </span>
                </motion.span>
              </h1>
              <h1 className="text-base-dark-gray">
                with{" "}
                <strong className="relative font-montserrat font-bold">
                  <span className="text-base-orange">Kirrho</span>
                  <span className="text-base-green">soft</span>
                  <motion.svg
                    width="406"
                    height="36"
                    viewBox="0 0 406 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      "absolute inset-x-0 -bottom-7 inline-block w-full"
                    }
                  >
                    <motion.path
                      initial={{ opacity: 0, pathLength: 0 }}
                      animate={{
                        opacity: 1,
                        pathLength: 1,
                      }}
                      transition={{
                        pathLength: {
                          delay: 1,
                          type: "spring",
                          duration: 2,
                          ease: "easeOut",
                        },
                        opacity: { delay: 0.8, duration: 0.3, ease: "easeIn" },
                      }}
                      d="M3 27.6429L403 3L202.501 33L384.047 20.2143"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={"#36454F"}
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_17_199"
                        x1="3"
                        y1="18"
                        x2="403"
                        y2="18"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop />
                        <stop offset="1" stopColor="#262626" />
                      </linearGradient>
                    </defs>
                  </motion.svg>
                </strong>
              </h1>
            </div>
          </motion.div>
          {/* Description */}
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
            }}
            className="mt-5 flex w-11/12 flex-col items-center space-y-2 text-center text-base-dark-gray lg:w-[50%]"
          >
            <p className="text-lg">
              We build smart, custom software solutions that simplify business
              management and drive sustainable growth in challenging markets.
            </p>
          </motion.div>
          {/* Learn About Us Button */}
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
            }}
          >
            <a
              href="#about"
              className="text-md group relative flex items-end space-x-4 border-2 border-base-green bg-base-light-green px-8 py-3 font-medium text-base-green shadow-lg transition duration-300 ease-in-out hover:border-base-green hover:text-base-white hover:shadow-2xl active:scale-95 lg:text-lg"
            >
              <span className="absolute bottom-0 left-0 h-2 w-2 bg-base-green duration-300 ease-in-out group-hover:h-full group-hover:w-full"></span>
              <span className="z-10">Learn About Us</span>
              <PiArrowBendRightDownBold className="z-10 size-4 animate-bounce text-base-green duration-300 ease-out group-hover:text-base-white lg:size-6" />
            </a>
          </motion.div>
        </div>
      </div>
      {/* Container for side images with absolute positioning */}
      <div className="pointer-events-none absolute inset-0 h-full">
        {/* Left side card images */}
        <motion.div
          initial={{ opacity: 0, left: -150 }}
          animate={{ opacity: 1, left: 0 }}
          transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
          className="absolute top-1/2 hidden !-translate-y-1/2 lg:block"
        >
          <div className="relative flex -translate-x-1/2 flex-col items-end space-y-7 2xl:-translate-x-1/3">
            <motion.img
              initial={{ rotate: -6 }}
              animate={{ rotate: 3 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
              src="assets/mobile1.png"
              alt=""
              className="min-h-0 w-48 rotate-3 rounded-lg shadow-lg"
            />
            <motion.img
              initial={{ rotate: -6 }}
              animate={{ rotate: 3 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
              src="assets/linechart.png"
              alt=""
              className="min-h-0 w-52 rotate-3 rounded-lg shadow-lg"
            />
          </div>
        </motion.div>

        {/* Right side card images */}
        <motion.div
          initial={{ opacity: 0, right: -150 }}
          animate={{ opacity: 1, right: 0 }}
          transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
          className="absolute top-1/2 hidden !-translate-y-1/2 lg:block"
        >
          <div className="relative flex translate-x-1/2 flex-col items-start space-y-7 2xl:translate-x-1/3">
            <motion.img
              initial={{ rotate: 6 }}
              animate={{ rotate: -3 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
              src="assets/piechart2.png"
              alt=""
              className="min-h-0 w-52 -rotate-3 rounded-lg shadow-lg"
            />
            <motion.img
              initial={{ rotate: 6 }}
              animate={{ rotate: -3 }}
              transition={{ delay: 0.8, duration: 1, ease: "easeInOut" }}
              src="assets/login2.png"
              alt=""
              className="min-h-0 w-48 -rotate-3 rounded-lg shadow-lg"
            />
          </div>
        </motion.div>
      </div>
    </SectionLayout>
  );
};

export default Hero;
