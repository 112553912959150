import React, { useState } from "react";
import { TECHNOLOGY_ITEMS } from "../constants/technologies";
import SectionLayout from "../layouts/SectionLayout";
import Marquee from "react-fast-marquee";

const TechStack: React.FC = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleClick = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <SectionLayout
      sectionId="tech-stack"
      customClassName="bg-gray-100 !py-0"
      fullWidth
    >
      <div className="relative w-full overflow-hidden bg-base-white py-10 shadow-lg">
        <Marquee
          speed={80}
          pauseOnHover={isPaused}
          gradient={false}
          className="py-2"
        >
          <div className="flex">
            {TECHNOLOGY_ITEMS.map((tech, index) => (
              <div
                key={`tech-${index}`}
                onClick={() => handleClick(tech.url)}
                className="shrink-0 transition-transform hover:scale-110"
              >
                <img
                  src={tech.icon}
                  alt={`${tech.title} icon`}
                  title={`${tech.title}`}
                  className="mx-10 size-20 cursor-pointer object-contain grayscale transition-all duration-300 hover:grayscale-0"
                  onMouseEnter={() => setIsPaused(true)}
                  onMouseLeave={() => setIsPaused(false)}
                />
              </div>
            ))}
          </div>
        </Marquee>
      </div>
    </SectionLayout>
  );
};

export default TechStack;
