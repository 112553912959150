const formatText = (text: string) => {
  const parts = text.split(/\*(.*?)\*/);
  return parts.map((part, index) => {
    // Every odd index is text that was between asterisks
    return index % 2 === 1 ? (
      <strong className="font-semibold" key={index}>
        {part}
      </strong>
    ) : (
      part
    );
  });
};

export default formatText;
