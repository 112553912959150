import { useState } from "react";
import { NAVBAR_ITEMS } from "../constants/navbar";
import NavButton, { MobileNavButton } from "./CommonComps/NavButton";
import { HiOutlineMenuAlt3 as Menu } from "react-icons/hi";
import { IoClose as Close } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleNavigate = () => {
    setIsMenuOpen(false);
  };

  // rendering the header component here
  return (
    <header className="sticky top-0 z-40 h-20 bg-base-white font-poppins text-base-black shadow-lg md:h-16">
      <nav
        id="navbar"
        className="mx-auto flex h-full w-[95%] items-center justify-between lg:w-[85%] xl:w-[80%]"
      >
        <a
          href="#hero"
          className="flex items-center justify-center font-montserrat"
        >
          <img
            className="size-14"
            src="assets/icons/k_soft_logo.svg"
            alt="Kirrhosoft logo"
          />

          <h1 className="text-2xl font-bold">
            <span className="text-base-orange">Kirrho</span>
            <span className="text-base-green">soft</span>
          </h1>
        </a>

        {/* Desktop Navigation */}
        <ul className="hidden items-center gap-3 text-sm md:flex lg:gap-6 lg:text-sm xl:text-base">
          {NAVBAR_ITEMS.map((item, index) => (
            <NavButton key={index} href={item.href} label={item.label} />
          ))}
        </ul>
        <a
          href="#contact"
          className="group relative hidden items-center border-2 border-base-orange/80 bg-base-light-orange/60 px-2 py-2 font-medium text-base-orange/80 shadow-md transition duration-300 ease-in-out hover:border-base-orange/80 hover:text-base-white active:scale-95 md:flex md:text-xs lg:px-4 lg:py-2 lg:text-sm xl:text-base"
        >
          <span className="absolute bottom-0 left-0 h-2 w-2 bg-base-orange/80 duration-300 ease-in-out group-hover:h-full group-hover:w-full"></span>
          <span className="z-10">CONTACT US</span>
        </a>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="z-50 md:hidden"
          aria-label="Toggle menu"
        >
          <motion.div
            animate={{ rotate: isMenuOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            {isMenuOpen ? (
              <Close size={32} className="text-base-black" />
            ) : (
              <Menu size={32} className="text-base-black" />
            )}
          </motion.div>
        </button>

        {/* Mobile Menu Overlay */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ clipPath: "circle(0% at 100% 0%)" }}
              animate={{ clipPath: "circle(150% at 100% 0%)" }}
              exit={{ clipPath: "circle(0% at 100% 0%)" }}
              transition={{
                duration: 0.8,
                ease: [0.76, 0, 0.024, 1],
              }}
              className="fixed inset-0 z-10 bg-base-gray"
            >
              <motion.ul
                initial="closed"
                animate="open"
                exit="closed"
                variants={{
                  open: {
                    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
                  },
                  closed: {
                    transition: { staggerChildren: 0.05, staggerDirection: -1 },
                  },
                }}
                className="flex h-screen flex-col items-center justify-center gap-6"
              >
                {NAVBAR_ITEMS.map((item, index) => (
                  <MobileNavButton
                    key={index}
                    href={item.href}
                    label={item.label}
                    onNavigate={handleNavigate}
                  />
                ))}
                <a
                  href="#contact"
                  onClick={handleNavigate}
                  className="group relative flex items-center border-2 border-base-orange/80 bg-base-light-orange/60 px-8 py-3 font-medium text-base-orange/80 shadow-lg transition duration-300 ease-in-out hover:border-base-orange/80 hover:text-base-white active:scale-95"
                >
                  <span className="absolute bottom-0 left-0 h-2 w-2 bg-base-orange/80 duration-300 ease-in-out group-hover:h-full group-hover:w-full"></span>
                  <span className="z-10">CONTACT US</span>
                </a>
              </motion.ul>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};

export default Header;
