export const OUR_SERVICE_ITEMS: Array<ourServices> = [
  {
    icon: [
      "assets/techLogos/figma.svg",
      "assets/techLogos/adobexd.svg",
      "assets/techLogos/illustrator.svg",
      "assets/techLogos/photoshop.svg",
    ],
    title: "UI/UX Design",
    description:
      "We craft seamless and visually appealing designs, ensuring your users enjoy an intuitive and engaging experience tailored to your brand.",
  },
  {
    icon: [
      "assets/techLogos/html.svg",
      "assets/techLogos/css.svg",
      "assets/techLogos/tailwind.svg",
      "assets/techLogos/javascript.svg",
      "assets/techLogos/typescript.svg",
      "assets/techLogos/react.svg",
      "assets/techLogos/vue.svg",
      "assets/techLogos/angular.svg",
      "assets/techLogos/webrtc.svg",
      "assets/techLogos/redux.svg",
      "assets/techLogos/vite.svg",
      "assets/techLogos/webpack.svg",
      "assets/techLogos/framer.svg",
    ],
    title: "Frontend Development",
    description:
      "Our frontend solutions combine creativity and technology, delivering pixel-perfect, responsive, and interactive interfaces that bring your vision to life.",
  },
  {
    icon: [
      "assets/techLogos/nodejs.svg",
      "assets/techLogos/express.svg",
      "assets/techLogos/python.svg",
      "assets/techLogos/django.svg",
      "assets/techLogos/drf.svg",
      "assets/techLogos/fast.svg",
      "assets/techLogos/flask.svg",
      "assets/techLogos/golang.svg",
      "assets/techLogos/graphql.svg",
      "assets/techLogos/oauth.svg",
      "assets/techLogos/jwt.svg",
      "assets/techLogos/redis.svg",
      "assets/techLogos/websocket.svg",
      "assets/techLogos/postgresql.svg",
      "assets/techLogos/mysql.svg",
      "assets/techLogos/mongodb.svg",
      "assets/techLogos/oracle.svg",
    ],
    title: "Backend Development",
    description:
      "We develop secure, scalable, and high-performance backend systems, ensuring your applications function smoothly and reliably under any workload.",
  },

  {
    icon: [
      "assets/techLogos/flutter.svg",
      "assets/techLogos/react_native.svg",
      "assets/techLogos/android.svg",
      "assets/techLogos/kotlin.svg",
      "assets/techLogos/swift.svg",
    ],
    title: "Mobile Development",
    description:
      "From concept to launch, we create innovative, high-performance mobile applications that deliver exceptional user experiences on all platforms.",
  },

  {
    icon: [
      "assets/techLogos/vitest.svg",
      "assets/techLogos/jest.svg",
      "assets/techLogos/pytest.svg",
    ],
    title: "Testing & Maintenance",
    description:
      "Our comprehensive testing and proactive maintenance services ensure your software remains error-free, secure, and up to date.",
  },
  {
    icon: [
      "assets/techLogos/aws.svg",
      "assets/techLogos/azure.svg",
      "assets/techLogos/docker.svg",
      "assets/techLogos/kubernetes.svg",
      "assets/techLogos/git.svg",
      "assets/techLogos/jenkins.svg",
      "assets/techLogos/nginx.svg",
    ],
    title: "DevOps",
    description:
      "We optimize your development lifecycle with DevOps solutions, enabling faster deployments, improved collaboration, and seamless operations.",
  },
];
