export const WORK_ITEMS: Array<Work> = [
  {
    image: "assets/images/eastwest-project.webp",
    title: "Recruitment Management System",
    subtitle: "HR & Recruitment Software",
    description:
      "Manages applicant tracking, interviews, business processes, documents, and expatriation processes for efficient talent acquisition for a major overseas recruitment office in Bangladesh.",
  },

  {
    image: "assets/images/appointment-project.webp",
    title: "Legal Consultancy Management System",
    subtitle: "Appointment Management Software",
    description:
      "Organizes client cases, appointments, document management, financial reports, accounting and billing for a legal consultancy service based out of Canada.",
  },

  {
    image: "assets/images/lamination-project.webp",
    title: "Lamination Factory Software",
    subtitle: "Manufacturing & Production Software",
    description:
      "Oversees production workflows, inventory, accounting, and business reports for lamination processes in a small manufacturing setup.",
  },

  {
    image: "assets/images/kindred-project.webp",
    title: "Outlet Management Software",
    subtitle: "Retail Management Software",
    description:
      "A web application for purchase and sales tracking with outlet-specific dashboards for users and admins. Enables detailed reporting and management of transactions per outlet for an established bakery chain.",
  },

  {
    image: "assets/images/porshi-project.webp",
    title: "Barcoded Stock & Batch Management",
    subtitle: "Inventory Management Software",
    description:
      "The web application to create and manage products/batches using barcode to simplify inventory management for a chain grocery store for efficient product tracking and custom label printing.",
  },

  {
    image: "assets/images/saas-project.webp",
    title: "SaaS Platform",
    subtitle: "Enterprise SaaS Software",
    description:
      "Our SaaS platform is currently under development with microservice architecture, designed to streamline business operations with powerful tools, intuitive interfaces, and a seamless user experience. Stay tuned for updates!",
  },
];
