import React from "react";

const SectionLayout = ({
  sectionId,
  children,
  customClassName,
  fullWidth,
}: {
  sectionId: string;
  children: React.ReactNode;
  customClassName?: string;
  fullWidth?: boolean;
}) => {
  // rendering section layout component here
  return (
    <section
      id={sectionId}
      className={`${
        fullWidth ? "w-full" : "w-[95%] md:w-4/5"
      } mx-auto py-14 md:py-20 ${customClassName}`}
    >
      {children}
    </section>
  );
};

export default SectionLayout;
